import React from "react";
import { Field, useFormState } from "react-final-form";
import { add_affiliate_form_keys } from "../../constants/AddAffiliate.constants";
import {
  required,
  validateEmail,
  validateMaxLength,
  validatePhoneNumber,
} from "features/Common/modules/Form/utils/Form.validations.utils";
import { ExlyInput, ExlyPhoneInput } from "common/form";
import { FormFieldLabel } from "features/Common/modules/Form/modules/FormFieldLabel/FormFieldLabel";
import { AffiliateCommissionForm } from "features/AffiliateMarketing/modules/AffiliateCommissionForm/AffiliateCommissionForm";
import { useAddAffiliateForm } from "./utils/useAddAffiliateForm";
import moduleStyle from "./AddAffiliateFrom.module.css";
import { country_codes } from "features/Common/modules/Phone/constants/CountryCodes.constants";
import { INPPUT_PROPS } from "features/Common/modules/Form/constants/Form.constants";

export const AddAffliateForm = ({ disableFields = false }) => {
  const { values: currentValues } = useFormState();
  const { handlePhoneNumberChange } = useAddAffiliateForm();
  return (
    <div>
      <div className={`my-3`}>
        <Field
          disabled={disableFields}
          name={add_affiliate_form_keys.full_name.key}
          label={
            <FormFieldLabel
              formKeys={add_affiliate_form_keys}
              formKey={add_affiliate_form_keys.full_name.key}
              required
            />
          }
          validate={(value) => {
            const check =
              required(value) ??
              validateMaxLength(
                value,
                add_affiliate_form_keys.full_name.max_length
              );
            return check;
          }}
          size="small"
          component={ExlyInput}
          fullWidth
          placeholder={add_affiliate_form_keys.full_name.placeholder}
        />
      </div>

      <div className={`mb-3`}>
        <Field
          disabled={disableFields}
          name={add_affiliate_form_keys.email.key}
          label={
            <FormFieldLabel
              formKeys={add_affiliate_form_keys}
              formKey={add_affiliate_form_keys.email.key}
              required
            />
          }
          validate={validateEmail}
          size="small"
          component={ExlyInput}
          fullWidth
          placeholder={add_affiliate_form_keys.email.placeholder}
        />
      </div>

      <div className={`mb-3`}>
        <FormFieldLabel
          formKeys={add_affiliate_form_keys}
          formKey={add_affiliate_form_keys.phone_number.key}
          required
        />
        <Field
          disabled={disableFields}
          component={ExlyPhoneInput}
          disableDropdown
          name={add_affiliate_form_keys.phone_number.key}
          country={country_codes.IN.code.toLowerCase()}
          placeholder={add_affiliate_form_keys.phone_number.placeholder}
          onChange={handlePhoneNumberChange}
          validate={validatePhoneNumber}
          copyNumbersOnly={false}
          source={add_affiliate_form_keys.phone_number.key}
          inputProps={{
            name: INPPUT_PROPS.phone,
          }}
          inputClass={`${moduleStyle.dropdown} ${moduleStyle.phoneBorder}`}
        />
      </div>

      <AffiliateCommissionForm currentValues={currentValues} />
    </div>
  );
};
